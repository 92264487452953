import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonContext } from "../../App";
import Modal from "../../components/ui/modal";
import { postCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";

const VipDisplay = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails, setActionDetails, actionDetails, setIsLoading } =
    useContext(CommonContext);
  const { state } = location || {};
  const notActivating =
    actionDetails?.screenName === "vip-activate" &&
    actionDetails?.isOpen &&
    actionDetails?.mode === "noWalletBalance";
  const activateNow = async () => {
    const isActivate =
      parseInt(userDetails?.wallet_balance || 0) >=
      parseInt(state?.deposit || 0);

    if (isActivate && state.id) {
      try {
        setIsLoading(true);
        const response = await postCall(URL.PLAN_SUBSCRIPTION, {
          plan_id: state.id,
        });
        if (response && response.status === 200) {
          const res = await response.json();

          if (!res || Object.keys(res).length === 0) {
            throw new Error("Empty response from server");
          }

          const { status_code, detail, error } = res;
          if (status_code === 200) {
            // toast.success(detail);
            navigate("/vip/activate/successfully");
          } else {
            toast.error(error);
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        if (error.message !== "HTTP error! Status: 401") {
          toast.error("Something went wrong. Contact your administrator");
        }
        console.error(error);
      }
    } else {
      setActionDetails({
        screenName: "vip-activate",
        isOpen: true,
        mode: "noWalletBalance",
      });
    }
  };

  return (
    <>
      <div className="vipDisplayMain">
        {vipDetails.map((item) => {
          const { name, label } = item;
          let value = state?.[name] || "-";

          if (name === "wallet_balance") {
            value = userDetails?.[name] || "-";
          }

          if (name === "wallet_balance" || name === "deposit") {
            value = value && value !== "-" ? "₹" + value : "-";
          }

          return (
            <div key={name}>
              <span>{label}</span>
              <p>{value}</p>
            </div>
          );
        })}

        <button onClick={activateNow} type="button">
          Activate Now
        </button>
        <button onClick={() => navigate(-1)} type="button">
          Return
        </button>
      </div>

      <Modal isOpen={notActivating}>
        <div className="WithdrawAlertModalContent">
          <p>You don't have enough amount in your wallet</p>
        </div>
      </Modal>
    </>
  );
};

export default VipDisplay;

const vipDetails = [
  {
    name: "wallet_balance",
    label: "Wallet Balance",
  },
  {
    name: "plan_level",
    label: "Recharge Plan",
  },
  {
    name: "deposit",
    label: "Deposit Amount",
  },
];
