import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { isSessionExpired, levelOptions } from "../../helper";
import { getCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import { CommonContext } from "../../App";
import NoDataFound from "../../components/errorPage/NoDataFound";
import TableContainer from "../../components/ui/table";
import { useParams } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-multi-date-picker";

const paginationName = "myTeamTable";
const MyTeam = () => {
  const { userId } = useParams();
  const { setIsLoading, paginationData } = useContext(CommonContext);
  const [levelValue, setLevelValue] = useState("1");
  const [teamDetails, setTeamDetails] = useState({
    data: [],
    lastmont_earned: 0,
    today_earned: 0,
    total_count: 0,
    total_rewards_earned: 0,
  });
  const [dateValue, setDateValue] = useState({
    from_date: moment().format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (userId && !isSessionExpired) {
      fetchMyTeamList();
    }
  }, [
    levelValue,
    userId,
    paginationData[paginationName],
    isSessionExpired,
    dateValue,
  ]);

  const fetchMyTeamList = async () => {
    try {
      const { page_no, rows_per_page } = paginationData[paginationName];
      const offset = page_no * rows_per_page;
      setIsLoading(true);
      const response = await getCall(
        `${URL.GET_MY_TEAM}/${userId}?lvl=${levelValue}&offset=${offset}&limit=${rows_per_page}&from_date=${dateValue.from_date}&to_date=${dateValue.to_date}`
      );
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setTeamDetails(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const handleDateChange = (date, key) => {
    const year = date.year;
    const month = String(date.month.number).padStart(2, "0");
    const day = String(date.day).padStart(2, "0");
    const correctedDate = moment(new Date(`${year}-${month}-${day}`)).format(
      "YYYY-MM-DD"
    );

    setDateValue((prev) => ({ ...prev, [key]: correctedDate }));
  };

  return (
    <div className="adminRecharges myTeamMain">
      <div className="adminRechargesHeader myTeamHeader">
        <div className="adminRechargesHeaderLabelAndSearch">
          <p className="contentTitle">My Team</p>
        </div>

        <div className="adminRechargesHeaderStatus">
          {levelOptions.map((level) => (
            <label key={`team-level-option-${level.value}`}>
              <input
                onChange={(e) => setLevelValue(e.target.value)}
                value={level.value}
                name="level"
                type="radio"
                checked={levelValue === level.value}
              />
              {level.name}
            </label>
          ))}
        </div>
      </div>

      <div className="teamKpiCards">
        {teamKpiCards.map((teamKpiCard) => {
          const value = teamDetails?.[teamKpiCard.name] || 0;
          return (
            <div key={teamKpiCard.name}>
              <p>{teamKpiCard.label}</p>
              <p>{value}</p>
            </div>
          );
        })}
      </div>

      <div className="myTeamCalendar normalCalendar">
        <div className="myTeamCalendarFirst">
          <span className="myTeamCalendarFirstSpan">From</span>
          <DatePicker
            onChange={(date) => handleDateChange(date, "from_date")}
            value={dateValue.from_date}
            format="YYYY-MM-DD"
            name="from_date"
            className="rmdp-mobile red"
            monthYearSeparator="|"
            headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
          />
        </div>
        <div className="myTeamCalendarSecond">
          <span className="myTeamCalendarSecondSpan">To</span>
          <DatePicker
            onChange={(date) => handleDateChange(date, "to_date")}
            value={dateValue.to_date}
            name="to_date"
            format="YYYY-MM-DD"
            className="rmdp-mobile red"
            monthYearSeparator="|"
            headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
          />
        </div>
      </div>
      <div className="adminRechargesTable">
        {teamDetails.data.length ? (
          <TableContainer
            tableName={tableDetails.tableName}
            columns={tableDetails.columns}
            data={teamDetails.data}
            paginationName={paginationName}
            total_count={teamDetails.total_count}
          >
            <tbody>
              {teamDetails.data.map((item, itemInd) => {
                return (
                  <tr
                    key={`${tableDetails.tableName.replace(
                      /\s/g,
                      "-"
                    )}-data-row-${itemInd + 1}`}
                  >
                    {tableDetails.keys.map((key, keyInd) => {
                      let value = item?.[key] || "-";

                      return (
                        <td
                          key={`${tableDetails.tableName.replace(
                            /\s/g,
                            "-"
                          )}-data-key-${keyInd + 1}`}
                        >
                          {value}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableContainer>
        ) : (
          <NoDataFound name="My Team" />
        )}
      </div>
    </div>
  );
};

const teamKpiCards = [
  {
    label: "Today Earned",
    name: "today_earned",
  },
  {
    label: "Last Month Earned",
    name: "lastmont_earned",
  },
  {
    label: "Total Count",
    name: "total_count",
  },
  {
    label: "Total Rewards Earned",
    name: "total_rewards_earned",
  },
];

const tableDetails = {
  columns: ["Mobile No", "Plan Level", "Referral Reward", "Task Reward", "Task Count"],
  keys: [
    "mobile_no",
    "plan_level",
    "referral_reward_total",
    "task_reward_total",
    "task_count",
  ],
  tableName: "My Team",
};

export default MyTeam;
