import React, { useContext } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { CommonContext } from "../../App";
import { URL } from "../../api/urls";
import { putCall } from "../../api/fetchConfig";

const validate = (values) => {
  const errors = {};

  if (values.video_file) {
    const validTypes = ["video/mp4", "video/mkv", "video/avi"];
    if (!validTypes.includes(values.video_file.type)) {
      errors.video_file = "mp4, mkv, and avi files are allowed.";
    }
  } else {
    errors.video_file = "Video File is required.";
  }

  return errors;
};

const VideoTutorialForm = ({ fetchVideoTutorial }) => {
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);

  const handleModalClose = () => setActionDetails({});

  const handleVideoTutorialFormSubmit = async (values) => {
    const formData = new FormData();
    formData.append(
      "video_details",
      JSON.stringify({
        id: values.id,
        is_active: values.is_active || true,
        is_delete: false,
      })
    );

    formData.append("video_file", values.video_file);

    try {
      setIsLoading(true);
      const response = await putCall(URL.GET_VIDEO_TUTORIAL, formData, true);
      if (response && response.status === 200) {
        const res = await response.json();

        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }

        const { status_code, detail } = res;
        if (status_code === 200) {
          fetchVideoTutorial();
          toast.success(detail);
          handleModalClose();
        } else {
          toast.error(res?.error || detail);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...actionDetails?.data, video_file: "" },
    onSubmit: handleVideoTutorialFormSubmit,
    validate,
  });

  const inputOnchange = (e, name) => {
    const { value, checked, files } = e.target;

    if (name === "is_active") {
      formik.setFieldValue(name, checked || false);
    } else if (name === "video_file") {
      formik.setFieldValue(name, files?.[0] || "");
    } else {
      formik.setFieldValue(name, value);
    }
  };

  return (
    <div className="planFormMain">
      <form onSubmit={formik.handleSubmit}>
        {renderFormFields.map((field, index) => {
          return field.type === "checkbox" ? (
            <div
              className="planInputWrapperCheckbox"
              key={`plan-form-field-${field.name}-${index + 1}`}
            >
              <div>
                <label htmlFor={field.name}>{field.label}</label>
                <input
                  name={field.name}
                  onChange={(e) => inputOnchange(e, field.name)}
                  value={formik.values[field.name]}
                  checked={formik.values[field.name]}
                  id={field.name}
                  type={field.type}
                />
              </div>
              <span className="errorText">{formik.errors[field.name]}</span>
            </div>
          ) : (
            <div
              className="planInputWrapper"
              key={`plan-form-field-${field.name}-${index + 1}`}
            >
              <div>
                <label htmlFor={field.name}>{field.label}</label>
                <input
                  name={field.name}
                  onChange={(e) => inputOnchange(e, field.name)}
                  id={field.name}
                  type={field.type}
                  accept=".mp4,.mkv,.avi"
                />
              </div>
              <span className="errorText">{formik.errors[field.name]}</span>
            </div>
          );
        })}

        <div className="btnGroup">
          <button onClick={handleModalClose} type="button">
            Close
          </button>
          <button type="submit">Update</button>
        </div>
      </form>
    </div>
  );
};

export default VideoTutorialForm;

const renderFormFields = [
  {
    label: "Video File",
    name: "video_file",
    type: "file",
  },

  //   {
  //     label: "Is Active",
  //     name: "is_active",
  //     type: "checkbox",
  //   },
];
