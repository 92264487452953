import React, { useContext } from "react";
import { toast } from "react-toastify";
import { CommonContext } from "../../App";
import { putCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";

const VideoTutorialDelete = ({ fetchVideoTutorial }) => {
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);

  const handleModalClose = () => setActionDetails({});

  const handleVideoTutorialDelete = async () => {
    try {
      const formData = new FormData();
      formData.append(
        "video_details",
        JSON.stringify({
          id: actionDetails?.data?.id,
          is_active: actionDetails?.data?.is_active,
          is_delete: true,
        })
      );

      setIsLoading(true);
      const response = await putCall(URL.GET_VIDEO_TUTORIAL, formData, true);
      if (response && response.status === 200) {
        const res = await response.json();

        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }

        const { status_code, detail, error } = res;
        if (status_code === 200) {
          fetchVideoTutorial();
          toast.success(detail);
          handleModalClose();
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  return (
    <div className="planFormMain">
      <p className="contentTitle planFormMainDeleteTitle">
        {actionDetails.label}
      </p>
      <div className="btnGroup">
        <button onClick={handleModalClose} type="button">
          No
        </button>
        <button onClick={handleVideoTutorialDelete} type="button">
          Yes
        </button>
      </div>
    </div>
  );
};

export default VideoTutorialDelete;
