import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { URL } from "../../api/urls";
import { CommonContext } from "../../App";
import { getCall } from "../../api/fetchConfig";
import { toast } from "react-toastify";
import TableCommonToShowStatusRelated from "../../components/tableRelated/statusRelatedTable";
import NoDataFound from "../../components/errorPage/NoDataFound";
import { isSessionExpired } from "../../helper";

const AccountingRecords = () => {
  const { setIsLoading, paginationData } = useContext(CommonContext);
  const [tabName, setTabName] = useState("profileAccountRecharge");
  const [tableDataList, setTableDataList] = useState({
    total_count: 0,
    data: [],
  });
  const { userId } = useParams();
  const buttonDetail = buttonGroups.find((item) => item.name === tabName);
  const paginationName =
    tabName === "profileAccountRecharge"
      ? "profileAccountingRecordRechargesTable"
      : "profileAccountingRecordWithdrawalTable";

  useEffect(() => {
    if (tabName && !isSessionExpired) {
      fetchList();
    }
  }, [userId, tabName, paginationData[paginationName], isSessionExpired]);

  const fetchList = async () => {
    try {
      const { page_no, rows_per_page } = paginationData[paginationName];
      const offset = page_no * rows_per_page;
      if (buttonDetail) {
        const { getUrlName } = buttonDetail;
        const apiUrl = `${getUrlName}/${userId}?offset=${offset}&limit=${rows_per_page}`;
        setIsLoading(true);
        const response = await getCall(apiUrl);
        if (response && response.status === 200) {
          const res = await response.json();
          const { status_code, data, error } = res;
          if (status_code === 200) {
            setTableDataList(data);
          } else {
            toast.error(error);
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const tabButtonClick = (name) => {
    setTabName(name);
  };

  return (
    <div className="accountingRecordsMain">
      <div className="accountingRecordsMainButtonGrp">
        {buttonGroups.map((button) => {
          const { name, label } = button;
          return (
            <button
              key={name}
              onClick={() => tabButtonClick(name)}
              className={tabName === name ? "active" : ""}
            >
              {label}
            </button>
          );
        })}
      </div>

      <div className="accountingRecordsTable">
        {buttonDetail ? (
          <TableCommonToShowStatusRelated
            columns={buttonDetail.tableDetails.columns}
            data={tableDataList.data}
            keys={buttonDetail.tableDetails.keys}
            tableName={buttonDetail.tableDetails.tableName}
            paginationName={paginationName}
            total_count={tableDataList.total_count}
          />
        ) : (
          <NoDataFound isFullName name="First you need to select tab" />
        )}
      </div>
    </div>
  );
};

export default AccountingRecords;

const buttonGroups = [
  {
    label: "Recharges",
    name: "profileAccountRecharge",
    tableDetails: {
      columns: ["Plan Level", "Status", "Date"],
      keys: ["plan_level", "status", "date"],
      tableName: "Recharges",
    },
    getUrlName: URL.GET_USER_RECHARGE,
  },
  {
    label: "Withdrawal",
    name: "profileAccountWithdraw",
    tableDetails: {
      columns: ["Amount", "Status", "Date"],
      keys: ["amount", "status", "date"],
      tableName: "Withdrawal",
    },
    getUrlName: URL.GET_WITHDRAWAL,
  },
];
