import { clearLocalStorage } from "../helper";

const BASE_URL = process.env.REACT_APP_RRR_API_BASE_URL;
const checkAdminRegex = /\badmin\b/g;
const isAdmin = checkAdminRegex.test(window.location.pathname);
// const loginRedirect = isAdmin ? "/admin/login" : "/login";

const getAccessToken = () => {
  return isAdmin
    ? sessionStorage.getItem("AdminAccessToken")
    : sessionStorage.getItem("AccessToken");
};

// Function to handle JSON responses
const handleJsonResponse = async (response) => {
  if (!response.ok) {
    if (response.status === 401) {
      sessionStorage.setItem("SessionExpired", true);
      // clearLocalStorage();
      // window.location.href = loginRedirect;
    }

    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return response;
};
// Function to handle other errors
const handleErrors = (error) => {
  console.error("Error:", error);
  throw error;
};

// Function to make a GET request
export const getCall = async (endpoint) => {
  const accessToken = getAccessToken();

  return fetch(`${BASE_URL}${endpoint}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(handleJsonResponse)
    .catch((error) => {
      handleErrors(error);
    });
};

// Function to make a POST request
export const postCall = async (endpoint, data, formData = false) => {
  const accessToken = getAccessToken();

  return fetch(`${BASE_URL}${endpoint}`, {
    method: "POST",
    headers: {
      ...(!formData && { "Content-Type": "application/json" }),
      Authorization: `Bearer ${accessToken}`,
    },

    body: formData ? data : JSON.stringify(data),
  })
    .then(handleJsonResponse)
    .catch((error) => {
      handleErrors(error);
    });
};

// Function to make a PUT request
export const putCall = async (endpoint, data, formData = false) => {
  const accessToken = getAccessToken();

  return fetch(`${BASE_URL}${endpoint}`, {
    method: "PUT",
    headers: {
      ...(!formData && { "Content-Type": "application/json" }),
      Authorization: `Bearer ${accessToken}`,
    },

    body: formData ? data : JSON.stringify(data),
  })
    .then(handleJsonResponse)
    .catch((error) => {
      handleErrors(error);
    });
};

// Function to make a DELETE request
export const deleteCall = async (endpoint, data) => {
  const accessToken = getAccessToken();

  return fetch(`${BASE_URL}${endpoint}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    ...(data && { body: JSON.stringify(data) }),
  })
    .then(handleJsonResponse)
    .catch((error) => {
      handleErrors(error);
    });
};
