import React, { useEffect, useState, useRef, useContext } from "react";
import { CommonContext } from "../../App";
import Modal from "../../components/ui/modal";

const WheelComponent = ({
  segments,
  segColors,
  winningSegment,
  onFinished,
  size = 150,
  buttonText = "Spin",
  fontFamily = "Arial",
  isCount,
}) => {
  const { actionDetails, setActionDetails } = useContext(CommonContext);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [currentSegment, setCurrentSegment] = useState("");
  const canvasRef = useRef(null);
  const angleCurrent = useRef(0); // Current rotation angle in radians
  const spinTimeout = useRef(null);
  const centerX = size + 20;
  const centerY = size + 20;
  const dimension = (size + 20) * 2;
  const isWinningSegAlert =
    actionDetails?.screenName === "winningSegment" && actionDetails?.isOpen;
  const starBlinkImgSrc = `${process.env.PUBLIC_URL}/assets/star-for-wheel.png`;

  // Draw the wheel
  const drawWheel = () => {
    const gifImage = new Image();
    gifImage.src = starBlinkImgSrc;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, dimension, dimension);

    const segmentAngle = (Math.PI * 2) / segments.length;
    let lastAngle = angleCurrent.current;

    segments.forEach((segment, index) => {
      const angle = lastAngle + segmentAngle;
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, size, lastAngle, angle, false);
      ctx.lineTo(centerX, centerY);
      ctx.closePath();
      ctx.fillStyle = segColors[index % segColors.length];
      ctx.fill();

      // Add segment text
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate((lastAngle + angle) / 2);
      ctx.textAlign = "right";
      ctx.fillStyle = "#fff";
      ctx.font = `800 18px ${fontFamily}`;
      ctx.fillText(segment, size - 10, 0);
      ctx.restore();

      lastAngle = angle;
    });

    // Draw the central button
    ctx.beginPath();
    ctx.arc(centerX, centerY, 30, 0, Math.PI * 2, false);
    ctx.closePath();
    ctx.fillStyle = "#AB60A0";
    ctx.fill();
    // const gradient = ctx.createRadialGradient(
    //   centerX,
    //   centerY,
    //   5,
    //   centerX,
    //   centerY,
    //   15
    // );
    // gradient.addColorStop(0, "#FFD700");
    // gradient.addColorStop(1, "#FFA500");

    gifImage.onload = () => {
      // Draw the GIF onto the canvas when it's loaded

      ctx.drawImage(gifImage, centerX - 20, centerY - 20, 40, 40);
    };

    // ctx.fillStyle = gradient;
    // ctx.textAlign = "center";
    // ctx.font = `bold 40px ${fontFamily}`;
    // ctx.fillText("★", centerX, centerY + 13);
  };

  // Draw the new needle
  const drawNeedle = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const needleX = centerX + size + 20; // Adjust X to the right of the wheel
    const needleY = centerY;

    // Needle properties
    ctx.beginPath();
    ctx.moveTo(needleX + 15, needleY - 15); // Point of the needle
    ctx.lineTo(needleX - 35, needleY); // Left side
    ctx.lineTo(needleX + 15, needleY + 15); // Right side
    ctx.lineWidth = 4;
    const gradient = ctx.createRadialGradient(
      centerX,
      centerY,
      5,
      centerX,
      centerY,
      15
    );
    gradient.addColorStop(0, "#FFD700"); // Bright yellow
    gradient.addColorStop(1, "#FFA500"); // Golden yellow
    ctx.strokeStyle = gradient;
    ctx.stroke();

    ctx.closePath();

    ctx.fillStyle = "#FFD700"; // Needle color
    ctx.fill();
  };

  // Function to start spinning
  const spin = () => {
    if (isSpinning) return;

    const winningIndex = segments.indexOf(winningSegment);
    if (winningIndex === -1) {
      setActionDetails({
        screenName: "winningSegment",
        isOpen: true,
        label: "Winning segment not found!",
      });
      return;
    }

    setIsSpinning(true);
    setIsFinished(false);

    const segmentAngle = (Math.PI * 2) / segments.length;
    const spins = 5; // Number of full spins
    const finalAngle =
      spins * Math.PI * 2 + // Full rotations
      (segments.length - winningIndex) * segmentAngle - // Align winning segment
      segmentAngle / 2; // Center the segment under the needle

    const spinDuration = 4000; // Total spin duration in ms
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;

      if (elapsed < spinDuration) {
        const progress = elapsed / spinDuration;
        const easing = 1 - Math.pow(1 - progress, 3); // Ease out cubic
        angleCurrent.current = easing * finalAngle;
        drawWheel();
        drawNeedle();
        requestAnimationFrame(animate);
      } else {
        angleCurrent.current = finalAngle % (Math.PI * 2);
        drawWheel();
        drawNeedle();
        setIsSpinning(false);
        setIsFinished(true);
        setCurrentSegment(winningSegment);
        onFinished && onFinished(winningSegment);
      }
    };

    requestAnimationFrame(animate);
  };

  // Initialize the canvas
  useEffect(() => {
    drawWheel();
    drawNeedle();

    return () => clearTimeout(spinTimeout.current);
  }, []);

  const spinnerStand = `${process.env.PUBLIC_URL}/assets/fortune-wheel-stand.png`;

  return (
    <>
      <div style={{ textAlign: "center", position: "relative", zIndex: 0 }}>
        <canvas
          id="spinnerCanvas"
          ref={canvasRef}
          width={dimension}
          height={dimension}
          style={{ display: "block" }}
        />
        <img
          style={{
            width: "200px",
            position: "absolute",
            top: "155px",
            margin: "auto",
            left: 0,
            zIndex: -1,
            right: 0,
          }}
          src={spinnerStand}
          alt="fortune-wheel-stand.png"
        />
        <button
          onClick={spin}
          disabled={isSpinning || !isCount}
          className="spinButton"
          style={{
            cursor: isSpinning ? "not-allowed" : "pointer",
            marginTop: "72px",
          }}
        >
          {isSpinning ? "Spinning..." : "Spin The Wheel"}
        </button>
        {/* {isFinished && (
          <div style={{ marginTop: "16px", fontSize: "20px", color: "green" }}>
            <strong>Winner :</strong> {currentSegment}
          </div>
        )} */}
      </div>

      <Modal isOpen={isWinningSegAlert}>
        <div className="WithdrawAlertModalContent">
          <p>{actionDetails?.label || ""}</p>
        </div>
      </Modal>
    </>
  );
};

export default WheelComponent;
