import React, { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../App";
import { useParams } from "react-router-dom";
import { getCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import TableContainer from "../../components/ui/table";
import { isSessionExpired } from "../../helper";

const DailyStatement = () => {
  const { userId } = useParams();
  const { setIsLoading } = useContext(CommonContext);
  const [dailyStatement, setDailyStatement] = useState({});

  useEffect(() => {
    if (!isSessionExpired) {
      fetchDailyStatement();
    }
  }, [userId, isSessionExpired]);

  const fetchDailyStatement = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(`${URL.GET_DAILY_STATEMENT}/${userId}`);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setDailyStatement(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  return (
    <div className="dailyStatementMain">
      <p className="contentTitle">Daily Statement</p>
      <div className="dailyStatementMainCardView">
        {dailyCardViewData.map((item) => {
          const value = dailyStatement?.[item.name] || 0;

          return (
            <div key={item.name}>
              <span className={item.name === "total" ? "highlight" : ""}>
                {value}
              </span>
              <span>{item.label}</span>
            </div>
          );
        })}
      </div>
      <div>
        <p style={{ marginBottom: "8px" }} className="contentTitle">
          Last 30 Days
        </p>
        <TableContainer
          tableName="profile-daily-statement"
          columns={columns}
          keys={keys}
          data={dailyStatement?.data || []}
        />
      </div>
    </div>
  );
};

export default DailyStatement;

const dailyCardViewData = [
  {
    name: "total",
    label: "Total revenue",
  },
  {
    name: "completed_task",
    label: "My completed task orders",
  },
  {
    name: "task_income",
    label: "My task income",
  },
  {
    name: "subordinates_completed_task",
    label: "Tasks completed by subordinates",
  },
  {
    name: "subordinates_task_income",
    label: "Subordinate job income",
  },
];

const columns = ["Date", "Quantity", "Task", "Team", "Activity"];
const keys = [
  "date",
  "completed_task",
  "daily_income",
  "reward_amount",
  "total",
];
