import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import "./style.css";
import { postCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import { CommonContext } from "../../App";
import { useLocation } from "react-router-dom";
import { clearLocalStorage } from "../../helper";

const validate = (values) => {
  const errors = {};

  if (!values.mobile_no) {
    errors.mobile_no = "Mobile number is required";
  } else if (values.mobile_no.length !== 10) {
    errors.mobile_no = "Please give valid Mobile Number";
  }
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Password must be at least 6 characters long";
  }

  return errors;
};

const Login = () => {
  const location = useLocation();
  const { setIsLoading } = useContext(CommonContext);
  const checkAdminRegex = /\badmin\b/g;
  const isAdminLogin = checkAdminRegex.test(location.pathname);
  const url = isAdminLogin ? URL.ADMIN_LOGIN_USER : URL.LOGIN_USER;
  const titleLabel = isAdminLogin ? "Admin Login" : "Login";

  useEffect(() => {
    clearLocalStorage();
  }, []);

  const onSubmit = async (values) => {
    const body = {
      ...values,
    };

    try {
      setIsLoading(true);
      const response = await postCall(url, body);
      if (response && response.status === 200) {
        const res = await response.json();

        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }

        const { access_token, refresh_token, error } = res;

        if (access_token) {
          setIsLoading(false);
          toast.success("Login successfully");
          if (isAdminLogin) {
            sessionStorage.setItem("AdminAccessToken", access_token);
            sessionStorage.setItem("AdminRefreshToken", refresh_token);
          } else {
            sessionStorage.setItem("AccessToken", access_token);
            sessionStorage.setItem("RefreshToken", refresh_token);
          }
        } else {
          setIsLoading(false);
          toast.error(error);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobile_no: "",
      password: "",
    },
    onSubmit,
    validate,
  });

  const inputOnchange = (e, name) => {
    const { value } = e.target;
    const regex = /[^0-9]/g;

    const checkMobileNumberCorrectly =
      name === "mobile_no" && (value.length > 10 || regex.test(value));
    if (checkMobileNumberCorrectly) {
      return;
    }

    formik.setFieldValue(name, value);
  };

  return (
    <div className="formMain">
      <form className="form" onSubmit={formik.handleSubmit}>
        <p className="formMainTitle">{titleLabel}</p>
        {renderFormFields.map((field, index) => {
          return (
            <div
              className="formWrap"
              key={`login-form-field-${field.name}-${index + 1}`}
            >
              <div className="formWrapGroup">
                <label htmlFor={field.name} className="formWrapIcon">
                  <i className={`fa-solid fa-${field.icon}`}></i>
                </label>
                <input
                  placeholder={field.label}
                  name={field.name}
                  onChange={(e) => inputOnchange(e, field.name)}
                  value={formik.values[field.name]}
                  id={field.name}
                  type={field.name !== "mobile_no" ? field.type : "text"}
                />
              </div>
              <span className="formWrapGroupErrorText">
                {formik.errors[field.name]}
              </span>
            </div>
          );
        })}

        <div className="formMainGroupButton">
          <span></span>
          <button type="submit" className="formButtonStyle ">
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;

const renderFormFields = [
  {
    label: "Mobile Number",
    name: "mobile_no",
    type: "number",
    icon: "phone",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    icon: "key",
  },
];
