import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { URL } from "../../api/urls";
import { getCall } from "../../api/fetchConfig";
import { CommonContext } from "../../App";
import { toast } from "react-toastify";
import WheelComponent from "./LuckyWheel";
import GiftPopup from "../../components/ui/modal/GiftPopup";
import CongratsWheel from "./CongratsWheel";
import { isSessionExpired } from "../../helper";

const TurntableOfLuck = () => {
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);
  const [userTurntableList, setUserTurntableList] = useState({
    total_count: 0,
    data: [],
  });
  const isWinningSuccessAlert =
    actionDetails?.screenName === "winningSuccess" && actionDetails?.isOpen;

  useEffect(() => {
    if (!isSessionExpired) {
      fetchUserTurntableList();
    }
  }, [isSessionExpired]);

  const fetchUserTurntableList = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(URL.GET_TURNTABLES);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setUserTurntableList(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const onFinished = (winner) => {
    const detail = userTurntableList.data.find((item) => item.amount == winner);

    setActionDetails({
      screenName: "winningSuccess",
      isOpen: !!detail,
      label: winner,
      data: detail,
    });
  };

  return (
    <>
      <div className="turntableOfLuck">
        <p className="contentTitle">Spin To Win</p>
        {/* <div>
            <span>Total Spin</span> <span>:</span>
            <span>{userTurntableList.total_count || 0}</span>
          </div> */}

        <div className="totalSpinGold">
          <img
            src={`${process.env.PUBLIC_URL}/assets/gold-ticket.png`}
            alt="gold-ticket.png"
          />
          <span>{userTurntableList.total_count || 0}</span>
        </div>

        <WheelComponent
          segments={userTurntableListStaticData}
          segColors={segColors}
          winningSegment={userTurntableList?.data?.[0]?.amount}
          onFinished={(winner) => onFinished(winner)}
          primaryColor="#832A4B"
          contrastColor="white"
          buttonText="Spin The Wheel"
          isOnlyOnce={false}
          isCount={userTurntableList?.total_count || 0}
          size={130}
          upDuration={100}
          downDuration={1000}
        />
      </div>

      <GiftPopup isOpen={isWinningSuccessAlert}>
        {isWinningSuccessAlert && (
          <CongratsWheel fetchList={fetchUserTurntableList} />
        )}
      </GiftPopup>
    </>
  );
};

export default TurntableOfLuck;

const userTurntableListStaticData = [
  100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600,
];
const segColors = [
  "#ff69b4",
  "#8a2be2",
  "rgb(53, 174, 164)",
  "#257bb4",
  "#ff69b4",
  "#8a2be2",
  "rgb(53, 174, 164)",
  "#257bb4",
  "#ff69b4",
  "#8a2be2",
  "rgb(53, 174, 164)",
  "#257bb4",
];
