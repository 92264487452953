import React, { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../../App";
import { URL } from "../../../api/urls";
import { getCall } from "../../../api/fetchConfig";
import { toast } from "react-toastify";
import {
  isSessionExpired,
  luckFormPayload,
  turntableStatusOptions,
} from "../../../helper";
import Modal from "../../../components/ui/modal";
import TurntableForm from "./TurntableForm";
import TableContainer from "../../../components/ui/table";
import moment from "moment";
import TurntableDel from "./TurntableDel";

const paginationName = "adminTurntableTable";

const AdminTurntables = () => {
  const { setIsLoading, actionDetails, setActionDetails, paginationData } =
    useContext(CommonContext);
  const [adminTurntableList, setAdminTurntableList] = useState({
    total_count: 0,
    data: [],
  });
  const [statusValue, setStatusValue] = useState("PENDING");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [debouncedPhoneNumberValue, setDebouncedPhoneNumberValue] =
    useState("");
  const isLuckFormModalEnable =
    actionDetails?.screenName === "addLuck" && actionDetails?.isOpen;
  const isLuckFormDeleteModal =
    actionDetails?.screenName === "deleteLuck" && actionDetails?.isOpen;

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedPhoneNumberValue(phoneNumber);
    }, 500);

    return () => clearTimeout(timer);
  }, [phoneNumber]);

  useEffect(() => {
    if (!isSessionExpired) {
      fetchAdminTurntableList();
    }
  }, [
    statusValue,
    debouncedPhoneNumberValue,
    paginationData[paginationName],
    isSessionExpired,
  ]);

  const fetchAdminTurntableList = async () => {
    try {
      const { page_no, rows_per_page } = paginationData[paginationName];
      const offset = page_no * rows_per_page;
      const apiUrl = `${URL.GET_ADMIN_TURNTABLES}?st=${statusValue}&mn=${debouncedPhoneNumberValue}&offset=${offset}&limit=${rows_per_page}`;
      setIsLoading(true);
      const response = await getCall(apiUrl);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setAdminTurntableList(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const handlePhoneNumber = (e) => {
    const input = e.target.value;

    if (input.length <= 10) {
      setPhoneNumber(input);
    }
  };

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const triggerLuck = (screenName, data, label) => {
    setActionDetails({
      screenName,
      data,
      isOpen: true,
      label,
    });
  };

  return (
    <>
      <div className="adminRecharges">
        <div className="adminRechargesHeader">
          <div className="adminRechargesHeaderLabelAndSearch">
            <p className="contentTitle">Turntable</p>
            <input
              onKeyPress={handleKeyPress}
              maxLength="10"
              value={phoneNumber}
              onChange={handlePhoneNumber}
              type="text"
              placeholder="Search With Mobile No"
            />
          </div>
          <div className="adminRechargesHeaderStatus">
            {turntableStatusOptions.map((status) => (
              <label key={`turntable-status-option-${status.value}`}>
                <input
                  onChange={(e) => setStatusValue(e.target.value)}
                  value={status.value}
                  name="status"
                  type="radio"
                  checked={statusValue === status.value}
                />
                {status.name}
              </label>
            ))}
            <button
              style={{ alignSelf: "center" }}
              className="addButton"
              onClick={() =>
                triggerLuck("addLuck", luckFormPayload, "Add Luck Amount")
              }
              type="button"
            >
              Add Luck Amount +
            </button>
          </div>
        </div>

        <div className="adminRechargesTable">
          <TableContainer
            tableName={turntableTableDetails.tableName}
            columns={turntableTableDetails.columns.filter((fil) =>
              statusValue === "PENDING"
                ? fil !== "Claimed Date"
                : statusValue === "CLAIMED"
                ? fil !== "Action"
                : fil
            )}
            data={adminTurntableList.data}
            paginationName={paginationName}
            total_count={adminTurntableList.total_count}
          >
            <tbody>
              {adminTurntableList.data.map((item, itemInd) => {
                return (
                  <tr
                    key={`${turntableTableDetails.tableName.replace(
                      /\s/g,
                      "-"
                    )}-data-row-${itemInd + 1}`}
                  >
                    {turntableTableDetails.keys
                      .filter((fil) =>
                        statusValue === "PENDING" ? fil !== "claimed_date" : fil
                      )
                      .map((key, keyInd) => {
                        let value = item?.[key] || "-";

                        if (key === "claimed_date" || key === "created_date") {
                          value = item?.[key]
                            ? moment(item?.[key]).format("DD-MM-YYYY")
                            : "-";
                        }
                        if (key === "amount") {
                          value = item?.[key].toString();
                        }

                        return (
                          <td
                            key={`${turntableTableDetails.tableName.replace(
                              /\s/g,
                              "-"
                            )}-data-key-${keyInd + 1}`}
                          >
                            {value}
                          </td>
                        );
                      })}
                    {statusValue === "PENDING" ? (
                      <td>
                        <div className="adminPlanTableButtonGroup">
                          <button
                            className="turntableEditButton"
                            onClick={() =>
                              triggerLuck(
                                "addLuck",
                                {
                                  id: item.id,
                                  amount: item.amount,
                                  user_mobile: item.mobile_no,
                                },
                                `Edit Luck "${item.amount}"`
                              )
                            }
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                          </button>
                          <button
                            onClick={() =>
                              triggerLuck(
                                "deleteLuck",
                                item,
                                `Are you sure to delete "${item.amount}" Amount for this "${item.mobile_no}"?`
                              )
                            }
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </TableContainer>
        </div>
      </div>

      <Modal
        label={isLuckFormModalEnable ? actionDetails?.label : ""}
        isOpen={isLuckFormModalEnable || isLuckFormDeleteModal}
      >
        {isLuckFormModalEnable && (
          <TurntableForm fetchList={fetchAdminTurntableList} />
        )}
        {isLuckFormDeleteModal && (
          <TurntableDel fetchList={fetchAdminTurntableList} />
        )}
      </Modal>
    </>
  );
};

export default AdminTurntables;

const turntableTableDetails = {
  columns: [
    "Mobile Number",
    "Amount",
    "Status",
    "Created Date",
    "Claimed Date",
    "Action",
  ],
  keys: ["mobile_no", "amount", "status", "created_date", "claimed_date"],
  tableName: "Turntable",
};
