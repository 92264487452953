import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import "react-multi-date-picker/styles/colors/red.css";
import { CommonContext } from "../../../App";
import { getCall, putCall } from "../../../api/fetchConfig";
import { URL } from "../../../api/urls";
import { toast } from "react-toastify";
import TableContainer from "../../../components/ui/table";
import moment from "moment";
import { isSessionExpired } from "../../../helper";

const Holidays = () => {
  const { setIsLoading } = useContext(CommonContext);
  const [holidaysData, setHolidaysData] = useState([]);
  const [holidayPayload, setHolidayPayload] = useState({
    addable_dates: [],
    deletable_dates: [],
  });
  const datePickerRef = useRef();

  useEffect(() => {
    if (!isSessionExpired) {
      fetchHolidays();
    }
  }, [isSessionExpired]);

  const fetchHolidays = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(URL.GET_HOLIDAYS);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setHolidaysData(data?.holidays);
          setHolidayPayload((prev) => ({
            ...prev,
            addable_dates: data?.holidays.map((date) => new Date(date)),
          }));
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const handleHolidaySubmit = async (values) => {
    if (values.addable_dates.length || values.deletable_dates.length) {
      try {
        setIsLoading(true);
        const response = await putCall(URL.GET_HOLIDAYS, values);
        if (response && response.status === 200) {
          const res = await response.json();

          if (!res || Object.keys(res).length === 0) {
            throw new Error("Empty response from server");
          }

          const { status_code, detail, error } = res;
          if (status_code === 200) {
            fetchHolidays();
            toast.success(detail);
          } else {
            toast.error(error);
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        if (error.message !== "HTTP error! Status: 401") {
          toast.error("Something went wrong. Contact your administrator");
        }
        console.error(error);
      }
    }
  };

  const handleChangeHolidays = (dates) => {
    const holidaysNewArr = dates.map((date) => {
      const year = date.year;
      const month = String(date.month.number).padStart(2, "0");
      const day = String(date.day).padStart(2, "0");
      return new Date(`${year}-${month}-${day}`);
    });

    const deletable_dates = holidaysData
      .filter(
        (date) =>
          !holidaysNewArr.find(
            (d) => new Date(date).getTime() === new Date(d).getTime()
          )
      )
      .map((date) => moment(date).format("YYYY-MM-DD"));

    const holidayPayloadBody = {
      addable_dates: holidaysNewArr.map((date) =>
        moment(date).format("YYYY-MM-DD")
      ),
      deletable_dates,
    };

    setHolidayPayload(holidayPayloadBody);
    handleHolidaySubmit(holidayPayloadBody);
  };

  const handleResetHolidays = () => {
    const holidayPayloadBody = {
      deletable_dates: holidaysData,
      addable_dates: [],
    };
    setHolidayPayload(holidayPayloadBody);
    handleHolidaySubmit(holidayPayloadBody);
  };

  return (
    <>
      <div className="adminUserMain homeHolidays">
        <div className="adminUserMainHeader">
          <p className="contentTitle">Holidays</p>
          <button
            onClick={() => datePickerRef.current.openCalendar()}
            type="button"
          >
            + Add Holiday
          </button>
        </div>
        <div className="adminUserMainTableWrapper">
          <TableContainer
            tableName={tableDetails.tableName}
            columns={tableDetails.columns}
            data={holidaysData}
          >
            <tbody>
              {holidaysData.map((date, itemInd) => {
                const convertDate = moment(date).format("LL");
                const convertDateWeek = moment(date).format("dddd");
                const dateValue =
                  convertDate && convertDate !== "Invalid date"
                    ? convertDate
                    : "-";
                const dateWeekValue =
                  convertDateWeek && convertDateWeek !== "Invalid date"
                    ? convertDateWeek
                    : "-";

                return (
                  <tr
                    key={`${tableDetails.tableName.replace(
                      /\s/g,
                      "-"
                    )}-data-row-${itemInd + 1}`}
                  >
                    <td>{dateValue}</td>
                    <td>{dateWeekValue}</td>
                  </tr>
                );
              })}
            </tbody>
          </TableContainer>
        </div>
      </div>

      <div className="homeHolidaysCalendar">
        <DatePicker
          onChange={handleChangeHolidays}
          value={holidayPayload.addable_dates}
          ref={datePickerRef}
          format="yyyy-MM-dd"
          className="rmdp-mobile red"
          monthYearSeparator="|"
          headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
          multiple
          mobileButtons={[
            {
              label: "RESET",
              type: "button",
              className: "rmdp-button rmdp-action-button",
              onClick: handleResetHolidays,
            },
          ]}
        />
      </div>
    </>
  );
};

export default Holidays;

const tableDetails = {
  columns: ["Date", "Date of the week"],
  keys: [],
  tableName: "Holidays",
};
