import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { CommonContext } from "../../App";
import { getCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import NoDataFound from "../../components/errorPage/NoDataFound";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { isSessionExpired } from "../../helper";

const VIP = () => {
  const { setIsLoading, userDetails, setBaseRecall } =
    useContext(CommonContext);
  const [vipPlanList, seVipPlanList] = useState([]);
  const navigate = useNavigate();
  const crownImg = `${process.env.PUBLIC_URL}/assets/home-vip.png`;

  useEffect(() => {
    if (!isSessionExpired) {
      fetchVipPlanList();
    }
  }, [isSessionExpired]);

  const fetchVipPlanList = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(URL.GET_PLANS);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          seVipPlanList(data);
          setBaseRecall(true);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  return (
    <div className="vipMain">
      <p className="contentTitle">VIP</p>

      {vipPlanList.length ? (
        <div className="vipMainPlans">
          {vipPlanList.map((item) => {
            const highlightValues = ["plan_level"];

            return (
              <div
                className={`vipMainPlanCard ${
                  userDetails?.plan_id === item.id ? "active" : ""
                }`}
                key={`vip-plan-${item.id}`}
              >
                <div className="vipMainPlanCardDetailFirstColumn">
                  {/* <LogoText /> */}
                  <div>
                    {vipPerCardDetails.map((cardItem) => {
                      const hightLightColor = highlightValues.includes(
                        cardItem.value
                      )
                        ? "highlight"
                        : "";
                      return (
                        <div
                          className="vipMainPlanCardDetails"
                          key={`vip-plan-card-${item.id}-${cardItem.value}`}
                        >
                          {cardItem.name ? (
                            <span className={`${hightLightColor}`}>
                              {cardItem.name}
                            </span>
                          ) : null}

                          <span className={`${hightLightColor}`}>
                            {item?.[cardItem.value]}{" "}
                            {cardItem.value === "validity" &&
                            item?.[cardItem.value]
                              ? " days"
                              : ""}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="vipMainPlanCardDetailSecondColumn">
                  <img src={crownImg} alt="vip crown" />
                  {!item.deposit ? null : userDetails?.plan_id === item.id ? (
                    <div className="effectiveDate">
                      <p>Effective Date:</p>
                      <p>
                        {userDetails?.subscribed_on && userDetails?.expires_on
                          ? `${moment(userDetails?.subscribed_on).format(
                              "YYYY-MM-DD"
                            )} - ${moment(userDetails?.expires_on).format(
                              "YYYY-MM-DD"
                            )}`
                          : "-"}
                      </p>
                    </div>
                  ) : item.is_active ? (
                    <button
                      onClick={() =>
                        navigate("/vip/display", {
                          state: {
                            ...item,
                          },
                        })
                      }
                    >
                      Join now
                    </button>
                  ) : (
                    <button disabled>Coming soon</button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <NoDataFound name="VIP Plans" />
      )}
    </div>
  );
};

const vipPerCardDetails = [
  {
    name: "",
    value: "plan_level",
  },
  {
    name: "Deposit",
    value: "deposit",
  },
  {
    name: "Tasks Per Day",
    value: "task_per_day",
  },
  {
    name: "Amount Per Task",
    value: "amount_per_task",
  },
  {
    name: "Validity",
    value: "validity",
  },
];

export default VIP;
