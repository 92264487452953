import React, { useContext, useEffect } from "react";
import Options from "../../container/home/Options";
import TopBanner from "../../container/home/TopBanner";
import ReferralBanner from "../../container/home/ReferralBanner";
import Plans from "../../container/home/Plans";
import { CommonContext } from "../../App";
import { isSessionExpired } from "../../helper";

const Home = () => {
  const checkAdminRegex = /\badmin\b/g;
  const isAdmin = checkAdminRegex.test(window.location.pathname);
  const { setBaseRecall } = useContext(CommonContext);

  useEffect(() => {
    if (!isAdmin && !isSessionExpired) {
      setBaseRecall(true);
    }
  }, [isAdmin, isSessionExpired]);

  return (
    <>
      <TopBanner />
      <Options />
      <ReferralBanner />
      <Plans />
      {/* <Tasks /> */}
    </>
  );
};

export default Home;
