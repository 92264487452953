import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { useFormik } from "formik";
import { CommonContext } from "../../App";
import { getCall, postCall } from "../../api/fetchConfig";
import { toast } from "react-toastify";
import { URL } from "../../api/urls";
import { useNavigate } from "react-router-dom";
import { isSessionExpired } from "../../helper";

const RechargeForm = ({ rechargeDetail }) => {
  const { setIsLoading } = useContext(CommonContext);
  const [planAmountOptions, setPlanAmountOptions] = useState([]);
  const domainUrl = "https://api.r999.in";
  const rechargeScanner = rechargeDetail?.upi_qr
    ? `${domainUrl}/${rechargeDetail?.upi_qr}`
    : "";
  const navigate = useNavigate();
  // const walletBalance = parseInt(userDetails?.wallet_balance || 0);

  useEffect(() => {
    if (!isSessionExpired) {
      fetchPlanList();
    }
  }, [isSessionExpired]);

  const fetchPlanList = async () => {
    try {
      const response = await getCall(URL.GET_PLANS);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setPlanAmountOptions(
            data
              .filter((plan) => plan.deposit)
              .map((item) => ({
                value: item.id,
                name: item.deposit,
                id: item.id,
              }))
          );
        } else {
          toast.error(error);
        }
      }
    } catch (error) {
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const validate = (values) => {
    const err = {};
    // const valWallet = !isNaN(parseInt(values.wallet_balance))
    //   ? parseInt(values.wallet_balance)
    //   : 0;

    // const isMoreWallet = valWallet > walletBalance;

    if (!values.plan_balance) {
      err.plan_balance = "Plan Balance is required";
    }
    if (!values.transaction_id) {
      err.transaction_id = "Transaction ID is required";
    }
    // if (isMoreWallet) {
    //   err.wallet_balance = "Give a correct wallet balance";
    // }

    if (values.files) {
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(values.files.type)) {
        err.files = "JPG, JPEG, and PNG files are allowed.";
      }
    } else {
      err.files = "File is required.";
    }

    return err;
  };

  const formik = useFormik({
    initialValues: {
      plan_balance: "",
      // wallet_balance: "",
      transaction_id: "",
      files: "",
    },
    onSubmit: (values) => handleSubmit(values),
    validate,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const getPlan = planAmountOptions.find(
    (item) => item.value == formik.values?.plan_balance
  );

  // const amountNeedToPay =
  //   parseInt(getPlan?.name || 0) - parseInt(formik.values?.wallet_balance || 0);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // if (name === "wallet_balance" && parseInt(value || 0) > walletBalance)
    //   return false;

    if (name === "files") {
      formik.setFieldValue(name, e.target.files[0]);
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();

    formData.append("file", values.files, values.files.name);

    const jsonData = {
      amount: parseInt(getPlan?.name || 0),
      upi_transaction_id: values.transaction_id,
      // plan_id: values.plan_balance,
      amount_receiver_id: rechargeDetail.id,
    };
    formData.append("json_data", JSON.stringify(jsonData));

    try {
      setIsLoading(true);
      const response = await postCall(URL.GET_USER_RECHARGE, formData, true);
      if (response && response.status === 200) {
        const res = await response.json();

        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }

        const { status_code, detail, error } = res;
        if (status_code === 200) {
          fetchPlanList();
          toast.success(detail);
          navigate("/recharge/success");
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  return (
    <form className="rechargeForm" onSubmit={formik.handleSubmit}>
      {/* <div className="rechargeFormUPIBox">
        <span>Wallet Balance :</span>
        <span>{walletBalance}</span>
      </div> */}

      <div className="rechargeFormAmount">
        <div>
          <label htmlFor="plan_balance">Plan Amount</label>
          <select
            onChange={handleChange}
            value={formik.values.plan_balance}
            name="plan_balance"
            id="plan_balance"
          >
            {planAmountOptions.length ? (
              <>
                {/* <option value="">None</option> */}
                <option disabled selected value="">
                  -- select an option --
                </option>
                {planAmountOptions.map((item) => (
                  <option
                    key={`recharge-form-plan-option-${item.id}`}
                    value={item.value}
                  >
                    {item.name}
                  </option>
                ))}
              </>
            ) : (
              <option disabled>No Options</option>
            )}
          </select>
          <span className="errorText">{formik.errors.plan_balance}</span>
        </div>

        {/* <div>
          <label htmlFor="wallet_balance">Wallet Balance</label>
          <input
            min={0}
            disabled={!walletBalance}
            onChange={handleChange}
            value={formik.values.wallet_balance}
            type="number"
            name="wallet_balance"
            id="wallet_balance"
          />
          <span className="errorText">{formik.errors.wallet_balance}</span>
        </div> */}
      </div>

      {/* <div className="rechargeFormUPIBox">
        <span>Amount needs to pay :</span>
        <span>{amountNeedToPay}</span>
      </div> */}

      <p className="rechargeFormSubtitle">QR Code</p>

      <div className="rechargeFormScanner">
        {rechargeScanner ? (
          <img src={rechargeScanner} alt="Recharge QR Code" />
        ) : (
          <div>QR Loading...</div>
        )}
      </div>

      <p className="rechargeFormSubtitle">(OR)</p>

      <div className="rechargeFormUPIBox">
        <span>UPI ID :</span>
        <span>{rechargeDetail.receiver_upi_id}</span>
      </div>

      <div className="uploadInput">
        <div className="transactionInput">
          <label htmlFor="transaction_id">Transaction ID</label>
          <input
            onChange={handleChange}
            value={formik.values.transaction_id}
            type="text"
            name="transaction_id"
            id="transaction_id"
          />
          <span className="errorText">{formik.errors.transaction_id}</span>
        </div>

        <input
          accept=".jpg,.jpeg,.png"
          onChange={handleChange}
          type="file"
          name="files"
          id="files"
        />
        <span className="errorText">{formik.errors.files}</span>
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export default RechargeForm;
