import { createContext, useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouterApp from "./routes";
import { getCall } from "./api/fetchConfig";
import { URL } from "./api/urls";
import { isSessionExpired } from "./helper";

const defaultPagination = { page_no: 0, rows_per_page: 10, total_count: 0 };

export const CommonContext = createContext(null);

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [paginationData, setPaginationData] = useState({
    adminUserTable: defaultPagination,
    adminPlanTable: defaultPagination,
    adminWithdrawTable: defaultPagination,
    adminRechargeTable: defaultPagination,
    adminTurntableTable: defaultPagination,
    adminBumperTable: defaultPagination,
    myTeamTable: defaultPagination,
    profileAccountingRecordRechargesTable: defaultPagination,
    profileAccountingRecordWithdrawalTable: defaultPagination,
  });
  const [baseRecall, setBaseRecall] = useState(false);
  const [actionDetails, setActionDetails] = useState({});
  const checkAdminRegex = /\badmin\b/g;
  const isAdmin = checkAdminRegex.test(window.location.pathname);
  const accessToken = isAdmin
    ? sessionStorage.getItem("AdminAccessToken")
    : sessionStorage.getItem("AccessToken");

  useEffect(() => {
    if ((accessToken || baseRecall) && !isSessionExpired) {
      fetchBaseDetails();
    }
  }, [accessToken, baseRecall, isSessionExpired]);

  const fetchBaseDetails = async () => {
    try {
      const apiUrl = `${URL.GET_HOME_BASE}`;
      setIsLoading(true);
      const response = await getCall(apiUrl);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setUserDetails({ ...data });
          setBaseRecall(false);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      userDetails,
      setUserDetails,
      actionDetails,
      setActionDetails,
      setBaseRecall,
      paginationData,
      setPaginationData,
    }),
    [
      isLoading,
      setIsLoading,
      userDetails,
      setUserDetails,
      actionDetails,
      setActionDetails,
      setBaseRecall,
      paginationData,
      setPaginationData,
    ]
  );

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CommonContext.Provider value={contextValue}>
        <RouterApp />
      </CommonContext.Provider>
    </div>
  );
}

export default App;
