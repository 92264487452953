import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import { postCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import { CommonContext } from "../../App";

const validate = (values) => {
  const errors = {};

  if (!values.mobile_no) {
    errors.mobile_no = "Mobile number is required";
  } else if (values.mobile_no.length !== 10) {
    errors.mobile_no = "Please give valid Mobile Number";
  }
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Password must be at least 6 characters long";
  }
  if (!values.transaction_password) {
    errors.transaction_password = "Transaction Password is required";
  } else if (values.transaction_password.length < 6) {
    errors.transaction_password =
      "Transaction Password must be at least 6 characters long";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Confirm Password is required";
  } else if (values.confirm_password.length < 6) {
    errors.confirm_password =
      "Confirm Password must be at least 6 characters long";
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = "Confirm Password should be matches the password";
  }

  return errors;
};

const Register = () => {
  const { sponsorId: sponsor_id } = useParams();
  const { setIsLoading } = useContext(CommonContext);
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem("sponsor_id", sponsor_id);
  }, [sponsor_id]);
  const onSubmit = async (values) => {
    const body = {
      ...values,
    };
    delete body["confirm_password"];

    try {
      setIsLoading(true);
      const response = await postCall(URL.REGISTER_USER, body);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, detail, error } = res;

        if (status_code === 200) {
          setIsLoading(false);
          toast.success(detail);
          navigate("/registration/success");
          sessionStorage.setItem("mobile_no", body.mobile_no);
        } else {
          setIsLoading(false);
          toast.error(error);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mobile_no: "",
      password: "",
      transaction_password: "",
      sponsor_id,
      confirm_password: "",
    },
    onSubmit,
    validate,
  });

  const inputOnchange = (e, name) => {
    const { value } = e.target;
    const regex = /[^0-9]/g;

    const checkMobileNumberCorrectly =
      name === "mobile_no" && (value.length > 10 || regex.test(value));
    if (checkMobileNumberCorrectly) {
      return;
    }

    formik.setFieldValue(name, value);
  };

  const navigateLoginBtn = () => {
    navigate("/login");
  };

  return (
    <div className="formMain">
      <form className="form" onSubmit={formik.handleSubmit}>
        <p className="formMainTitle">Register</p>
        {renderFormFields.map((field, index) => {
          return (
            <div
              className="formWrap"
              key={`register-form-field-${field.name}-${index + 1}`}
            >
              <div className="formWrapGroup">
                <label htmlFor={field.name} className="formWrapIcon">
                  <i className={`fa-solid fa-${field.icon}`}></i>
                </label>
                <input
                  placeholder={field.label}
                  name={field.name}
                  onChange={(e) => inputOnchange(e, field.name)}
                  value={formik.values[field.name]}
                  readOnly={field.name === "sponsor_id"}
                  id={field.name}
                  type={field.name !== "mobile_no" ? field.type : "text"}
                />
              </div>
              <span className="formWrapGroupErrorText">
                {formik.errors[field.name]}
              </span>
            </div>
          );
        })}
        <div className="formMainGroupButton">
          <button
            onClick={navigateLoginBtn}
            type="button"
            className="formButtonStyle "
          >
            Have an Account? Login
          </button>
          <button type="submit" className="formButtonStyle ">
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;

const renderFormFields = [
  {
    label: "Mobile Number",
    name: "mobile_no",
    type: "number",
    icon: "phone",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    icon: "key",
  },
  {
    label: "Confirm Password",
    name: "confirm_password",
    type: "password",
    icon: "key",
  },
  {
    label: "Transaction Password",
    name: "transaction_password",
    type: "password",
    icon: "key",
  },
  {
    label: "Sponser Id",
    name: "sponsor_id",
    type: "",
    icon: "envelope",
  },
];
