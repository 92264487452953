import React, { useContext } from "react";
import { CommonContext } from "../../../App";
import { toast } from "react-toastify";
import { deleteCall } from "../../../api/fetchConfig";
import { URL } from "../../../api/urls";

const TurntableDel = ({ fetchList }) => {
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);

  const handleModalClose = () => setActionDetails({});

  const handlePlanDelete = async () => {
    try {
      setIsLoading(true);
      const response = await deleteCall(
        `${URL.GET_ADMIN_TURNTABLES}/${actionDetails.data.id}`
      );
      if (response && response.status === 200) {
        const res = await response.json();

        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }

        const { status_code, detail, error } = res;
        if (status_code === 200) {
          fetchList();
          toast.success(detail);
          handleModalClose();
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  return (
    <div className="planFormMain">
      <p className="contentTitle planFormMainDeleteTitle">
        {actionDetails.label}
      </p>
      <div className="btnGroup">
        <button onClick={handleModalClose} type="button">
          No
        </button>
        <button onClick={handlePlanDelete} type="button">
          Yes
        </button>
      </div>
    </div>
  );
};

export default TurntableDel;
