import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { CommonContext } from "../../App";
import { getCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import NoDataFound from "../../components/errorPage/NoDataFound";
import { useNavigate } from "react-router-dom";
import { isBusinessDay, isSessionExpired } from "../../helper";
import Modal from "../../components/ui/modal";

const Tasks = () => {
  const { setIsLoading, setUserDetails, actionDetails } =
    useContext(CommonContext);
  const [taskList, setTaskList] = useState({
    videos: [],
  });
  const isPlanNotToSeeAlert =
    actionDetails?.screenName === "planNotToSee" && actionDetails?.isOpen;
  const noPlan =
    actionDetails?.screenName === "noPlan" && actionDetails?.isOpen;

  useEffect(() => {
    if (!isSessionExpired) {
      fetchTasks();
    }
  }, [isSessionExpired]);
  const fetchTasks = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(URL.GET_TASKS);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setTaskList(data);
          setUserDetails((prev) => ({
            ...prev,
            amount_per_task: data.amount_per_task,
            plan: data.plan_level,
            plan_level: data.plan_level,
            total_completed_tasks_today: data.task_completed,
            task_per_day: data.total_task,
          }));
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  return (
    <>
      <div className="taskMain">
        {taskList.videos.length ? (
          <>
            <p className="contentTitle">Tasks</p>
            <div className="userProfileKpiCards">
              <div>
                <p style={{ color: "var(--textGrayColor)" }}>Total Tasks</p>
                <p style={{ color: "var(--secondaryColor2)" }}>
                  {taskList?.total_task || "0"}
                </p>
              </div>
              <div>
                <p style={{ color: "var(--textGrayColor)" }}>Completed Tasks</p>
                <p style={{ color: "var(--secondaryColor2)" }}>
                  {taskList?.task_completed || "0"}
                </p>
              </div>
            </div>
            <TaskListShowing data={taskList} />
          </>
        ) : (
          <NoDataFound name="Tasks" />
        )}
      </div>

      <Modal isOpen={isPlanNotToSeeAlert || noPlan}>
        <div className="WithdrawAlertModalContent">
          <p>
            {noPlan
              ? "You don't have a plan. Kindly Recharge"
              : "Tasks can not be done on Holidays"}
          </p>
        </div>
      </Modal>
    </>
  );
};

const TaskListShowing = ({ data }) => {
  const { userDetails, setActionDetails } = useContext(CommonContext);
  const domainUrl = "https://api.r999.in";
  const navigate = useNavigate();

  const navigateButton = (path) => {
    if (!userDetails.plan_id) {
      setActionDetails({
        screenName: "noPlan",
        isOpen: true,
      });
      return null;
    } else if (!isBusinessDay(userDetails?.holidays, true)) {
      setActionDetails({
        screenName: "planNotToSee",
        isOpen: true,
      });
      return null;
    }
    navigate(path);
  };

  return (
    <div className="taskMainListContainer">
      {data.videos.map((task) => {
        return (
          <div
            key={`task-main-list-video-${task.id}`}
            className="tasksMainContainerBox"
          >
            <div>{data?.plan_level || "No Plan"}</div>
            <button
              onClick={() =>
                navigateButton(
                  `/tasks/display/${encodeURIComponent(task.file_path)}`
                )
              }
            >
              <img
                width="100%"
                height="100%"
                src={`${domainUrl}/${task.thumbnail_path}`}
                alt={`task-main-video-${task.thumbnail_path}`}
              />
            </button>

            <div>₹{data?.amount_per_task || "0"}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Tasks;
