import React, { useContext } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { addAdminBumperFormPayload } from "../../../helper";
import { CommonContext } from "../../../App";
import { putCall } from "../../../api/fetchConfig";
import { URL as APIURL } from "../../../api/urls";

const validate = (values) => {
  const err = {};

  if (!values.product_name) {
    err.product_name = "Product Name is required";
  }
  if (!values.user_mobile) {
    err.user_mobile = "User Mobile is required";
  }

  if (values.file) {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (
      typeof values.file === "object" &&
      !validTypes.includes(values.file.type)
    ) {
      err.file = "Only JPG, JPEG, and PNG file are allowed.";
    }
  } else {
    err.file = "Reward File is required.";
  }

  return err;
};

const AdminBumperForm = ({ fetchList }) => {
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);
  const domainUrl = "https://api.r999.in";

  const handleModalClose = () => {
    setActionDetails({});
  };

  const bumperFormFormik = useFormik({
    enableReinitialize: true,
    initialValues: actionDetails?.data || { ...addAdminBumperFormPayload },
    onSubmit: (values) => handleSubmit(values),
    validate,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "file") {
      bumperFormFormik.setFieldValue(name, e.target.files[0]);
    } else {
      bumperFormFormik.setFieldValue(name, value);
    }
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();

    `${domainUrl}/${values.product_path}` !== values.file &&
      formData.append("file", values.file);

    const jsonData = {
      id: values.id,
      product_name: values.product_name,
      user_mobile: values.user_mobile,
      product_change: `${domainUrl}/${values.product_path}` !== values.file,
      product_path: values.product_path,
    };
    formData.append("json_data", JSON.stringify(jsonData));

    try {
      setIsLoading(true);
      const response = await putCall(
        APIURL.GET_ADMIN_BUMPER_REWARD,
        formData,
        true
      );
      if (response && response.status === 200) {
        const res = await response.json();
        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }
        const { status_code, detail, error } = res;
        if (status_code === 200) {
          toast.success(detail);
          handleModalClose();
          fetchList();
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const displayFileName =
    bumperFormFormik.values?.file?.name || actionDetails?.data?.fileName || "";

  return (
    <form
      className="adminReceiverForm"
      onSubmit={bumperFormFormik.handleSubmit}
    >
      <div className="adminReceiverFormSubtitle">
        <p>Reward Image</p>
        <div
          style={{ textAlign: "center" }}
          className="adminReceiverFormScanner"
        >
          {bumperFormFormik.values?.file ? (
            <img
              width="100%"
              height="100%"
              src={
                typeof bumperFormFormik.values?.file === "object"
                  ? URL.createObjectURL(bumperFormFormik.values?.file)
                  : bumperFormFormik.values?.file
              }
              alt="QR Code"
            />
          ) : (
            <div>Reward Image not found</div>
          )}
        </div>
      </div>

      <span className="errorText"></span>

      {formFields.map((field) => {
        const { name, label, type } = field;

        return (
          <React.Fragment key={name}>
            <label className="adminReceiverFormLabel">
              <p>{label}</p>
              {type === "textArea" ? (
                <textarea
                  onChange={handleChange}
                  value={bumperFormFormik.values?.[name]}
                  name={name}
                  rows="4"
                />
              ) : name === "user_mobile" ? (
                <input
                  onKeyPress={handleKeyPress}
                  maxLength="10"
                  value={bumperFormFormik.values?.[name]}
                  onChange={handleChange}
                  type={type}
                  name={name}
                />
              ) : (
                <input
                  onChange={handleChange}
                  {...(type === "file"
                    ? {}
                    : { value: bumperFormFormik.values?.[name] })}
                  type={type}
                  name={name}
                />
              )}
            </label>
            {type === "file" && displayFileName && (
              <span className="adminReceiverFormFileLabel">
                {displayFileName}
              </span>
            )}
            <span className="errorText">{bumperFormFormik.errors[name]}</span>
          </React.Fragment>
        );
      })}

      <div className="adminReceiverFormBtnGroup">
        <button onClick={handleModalClose} type="button">
          Close
        </button>
        <button type="submit">Update</button>
      </div>
    </form>
  );
};

export default AdminBumperForm;

const formFields = [
  {
    name: "file",
    label: "Upload Reward",
    type: "file",
  },
  {
    name: "product_name",
    label: "Product Name",
    type: "text",
  },
  {
    name: "user_mobile",
    label: "User Mobile",
    type: "text",
  },
];
