import React, { useContext, useEffect, useState } from "react";
import "./style.css";

import { CommonContext } from "../../App";
import { getCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import NoDataFound from "../../components/errorPage/NoDataFound";
import Modal from "../../components/ui/modal";
import VideoTutorialDelete from "../../container/videoTutorial/VideoTutorialDelete";
import { isSessionExpired, videoTutorialFormPayload } from "../../helper";
import VideoTutorialForm from "../../container/videoTutorial/VideoTutorialForm";
const VideoTutorial = () => {
  const { setIsLoading, setActionDetails, actionDetails } =
    useContext(CommonContext);
  const [videoDetails, setVideoDetails] = useState({});
  const checkAdminRegex = /\badmin\b/g;
  const isAdmin = checkAdminRegex.test(window.location.pathname);
  const domainUrl = "https://api.r999.in";

  const isVideoTutorialFormModalEnable =
    actionDetails?.screenName === "updateVideoTutorial" &&
    actionDetails?.isOpen;

  const isVideoTutorialDelModalEnable =
    actionDetails?.screenName === "deleteVideoTutorial" &&
    actionDetails?.isOpen;

  useEffect(() => {
    if (!isSessionExpired) {
      fetchVideoTutorial();
    }
  }, [isSessionExpired]);

  const fetchVideoTutorial = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(URL.GET_VIDEO_TUTORIAL);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error, detail } = res;
        if (status_code === 200) {
          setVideoDetails(data);
        } else {
          toast.error(error || detail);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const triggerPlan = (screenName, data, label) => {
    setActionDetails({
      screenName,
      data,
      isOpen: true,
      label,
    });
  };

  const constructVideoUrl = videoDetails?.id
    ? `${domainUrl}/${videoDetails.file_path}`
    : "";
  const constructThumbnailUrl = videoDetails?.id
    ? `${domainUrl}/${videoDetails.thumbnail_path}`
    : "";

  return (
    <>
      <div className="videoTutorialMain">
        <div
          className={`videoTutorialMainTitleWrapper ${
            !isAdmin || videoDetails?.id ? "center" : ""
          } `}
        >
          <p className="contentTitle">Video Tutorial</p>
          {isAdmin && !videoDetails?.id ? (
            <button
              onClick={() =>
                triggerPlan(
                  "updateVideoTutorial",
                  videoTutorialFormPayload,
                  "Video Tutorial"
                )
              }
              type="button"
            >
              Add Video Tutorial +
            </button>
          ) : null}
        </div>

        {videoDetails?.id ? (
          <div
            className={`videoTutorialDisplayMainVideo ${isAdmin ? "edit" : ""}`}
          >
            {isAdmin ? (
              <div className="videoTutorialDisplayMainVideoTop">
                <button
                  onClick={() =>
                    triggerPlan(
                      "updateVideoTutorial",
                      videoDetails,
                      "Edit Video Tutorial"
                    )
                  }
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
                <button
                  onClick={() =>
                    triggerPlan(
                      "deleteVideoTutorial",
                      videoDetails,
                      "Are you sure to delete Video Tutorial?"
                    )
                  }
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
            ) : null}

            <video poster={constructThumbnailUrl} width="100%" controls>
              <source src={constructVideoUrl} type="video/mp4" />
            </video>
          </div>
        ) : (
          <NoDataFound name="Video Tutorial" />
        )}
      </div>

      <Modal
        label={isVideoTutorialFormModalEnable ? actionDetails?.label : ""}
        isOpen={isVideoTutorialFormModalEnable || isVideoTutorialDelModalEnable}
      >
        {isVideoTutorialFormModalEnable ? (
          <VideoTutorialForm fetchVideoTutorial={fetchVideoTutorial} />
        ) : (
          <VideoTutorialDelete fetchVideoTutorial={fetchVideoTutorial} />
        )}
      </Modal>
    </>
  );
};

export default VideoTutorial;
