import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { CommonContext } from "../../App";
import { getCall, postCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { isSessionExpired } from "../../helper";

const validate = (values) => {
  const err = {};

  if (!values.withdrawal_amount) {
    err.withdrawal_amount = "Quantity is required";
  }
  if (!values.password) {
    err.password = "Password is required";
  } else if (values.password.length < 6) {
    err.password = "Password must be at least 6 characters long";
  }

  return err;
};

const UserWithDraw = () => {
  const { setIsLoading, userDetails } = useContext(CommonContext);
  const [withDrawalData, setWithDrawalData] = useState({});
  const [isOpenAccountInfo, setIsOpenAccountInfo] = useState(false);

  useEffect(() => {
    if (!isSessionExpired) {
      fetchWithDrawal();
    }
  }, [isSessionExpired]);

  const fetchWithDrawal = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(URL.GET_WITHDRAWAL);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setWithDrawalData(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const withdrawalFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: 0,
      status: "PENDING",
      withdrawal_amount: 0,
      bank_acc_number: withDrawalData?.account_number || "",
      password: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        const response = await postCall(URL.GET_WITHDRAWAL, values);
        if (response && response.status === 200) {
          const res = await response.json();

          if (!res || Object.keys(res).length === 0) {
            throw new Error("Empty response from server");
          }

          const { status_code, detail, error } = res;
          if (status_code === 200) {
            resetForm();
            fetchWithDrawal();
            toast.success(detail);
          } else {
            toast.error(error);
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        if (error.message !== "HTTP error! Status: 401") {
          toast.error("Something went wrong. Contact your administrator");
        }
        console.error(error);
      }
    },
    validate,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <form onSubmit={withdrawalFormik.handleSubmit} className="userWithDrawMain">
      <p className="contentTitle">Withdrawal</p>
      {!Object.keys(withDrawalData).length ? (
        <div className="userWithDrawMainErr">
          <p>Please fill the bank details</p>
          <Link to={`/withdraw/bank-details/${userDetails?.user_id}`}>
            Click Here
          </Link>
        </div>
      ) : (
        <div className="userWithDrawMainForm">
          <div className="userWithDrawMainFormWalletBalance">
            <span>Wallet Balance : </span>
            <span>{withDrawalData?.wallet_balance || 0}</span>
          </div>

          <div className="userWithDrawMainFormBankInfo">
            <button
              type="button"
              onClick={() => setIsOpenAccountInfo(!isOpenAccountInfo)}
            >
              Account Information
            </button>

            <div
              className={`userWithDrawMainFormBankInfoCard ${
                isOpenAccountInfo ? "show" : "hide"
              }`}
            >
              {bankDetails.map((detail) => {
                return (
                  <div key={`account-info-${detail.name}`}>
                    <span>{detail.label}</span>
                    <span>{withDrawalData?.[detail.name] || "-"}</span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="userWithDrawMainFormQuantity">
            <p>Quantity</p>
            <div>
              {quantities.map((quantity) => {
                return (
                  <button
                    name="withdrawal_amount"
                    onClick={() =>
                      withdrawalFormik.setFieldValue(
                        "withdrawal_amount",
                        quantity.value
                      )
                    }
                    disabled={withDrawalData?.wallet_balance < quantity.value}
                    className={`${
                      withdrawalFormik.values.withdrawal_amount ===
                      quantity.value
                        ? "active"
                        : ""
                    }`}
                    type="button"
                    key={`user-withdrawal-quantity-${quantity.value}`}
                  >
                    {quantity.name}
                  </button>
                );
              })}
            </div>
            <span className="errorText">
              {withdrawalFormik.errors.withdrawal_amount}
            </span>
          </div>

          <div className="userWithDrawMainFormPassword">
            <label htmlFor="password">Password</label>
            <input
              onChange={withdrawalFormik.handleChange}
              value={withdrawalFormik.values.password}
              type="password"
              name="password"
              id="password"
            />
            <span className="errorText">
              {withdrawalFormik.errors.password}
            </span>
          </div>
          <button type="submit" className="userWithDrawMainFormSubmitButton">
            Submit
          </button>
        </div>
      )}
    </form>
  );
};

export default UserWithDraw;

const quantities = [
  { name: "300", value: 300 },
  { name: "800", value: 800 },
  { name: "2500", value: 2500 },
  { name: "8000", value: 8000 },
  { name: "24000", value: 24000 },
  { name: "50000", value: 50000 },
  { name: "100000", value: 100000 },
  { name: "200000", value: 200000 },
  { name: "500000", value: 500000 },
  { name: "1000000", value: 1000000 },
];

const bankDetails = [
  { label: "Bank Name", name: "bank_name" },
  { label: "Account Name", name: "account_name" },
  { label: "Account Number", name: "account_number" },
  { label: "Branch", name: "branch" },
  { label: "IFSC Code", name: "ifsc_code" },
];
