import React, { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../../App";
import { getCall } from "../../../api/fetchConfig";
import { URL } from "../../../api/urls";
import { toast } from "react-toastify";
import "./style.css";
import { isSessionExpired, statusOptions } from "../../../helper";
import TableCommonToShowStatusRelated from "../../../components/tableRelated/statusRelatedTable";

const paginationName = "adminRechargeTable";

const AdminRecharges = () => {
  const { setIsLoading, paginationData } = useContext(CommonContext);
  const [rechargeList, setRechargeList] = useState({
    total_count: 0,
    data: [],
  });
  const [statusValue, setStatusValue] = useState("PENDING");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [debouncedPhoneNumberValue, setDebouncedPhoneNumberValue] =
    useState("");
  const checkProfileRegex = /\bprofile\b/g;
  const isProfile = checkProfileRegex.test(window.location.pathname);
  const [isStatusEdit, setIsStatusEdit] = useState({ id: null, isOpen: false });

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedPhoneNumberValue(phoneNumber);
    }, 500);

    return () => clearTimeout(timer);
  }, [phoneNumber]);

  useEffect(() => {
    if (!isSessionExpired) {
      fetchRechargeList();
    }
  }, [
    statusValue,
    debouncedPhoneNumberValue,
    paginationData[paginationName],
    isSessionExpired,
  ]);

  const fetchRechargeList = async () => {
    try {
      const { page_no, rows_per_page } = paginationData[paginationName];
      const offset = page_no * rows_per_page;
      const apiUrl = `${URL.GET_ADMIN_RECHARGE}?st=${statusValue}&mn=${debouncedPhoneNumberValue}&offset=${offset}&limit=${rows_per_page}`;
      setIsLoading(true);
      const response = await getCall(apiUrl);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setRechargeList(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message !== "HTTP error! Status: 401") {
        toast.error("Something went wrong. Contact your administrator");
      }
      console.error(error);
    }
  };

  const handlePhoneNumber = (e) => {
    const input = e.target.value;

    if (input.length <= 10) {
      setPhoneNumber(input);
    }
  };

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="adminRecharges">
      <div className={`adminRechargesHeader ${isProfile ? "isProfile" : ""}`}>
        <div className="adminRechargesHeaderLabelAndSearch">
          <p className="contentTitle">Recharges</p>
          {!isProfile ? (
            <input
              onKeyPress={handleKeyPress}
              maxLength="10"
              value={phoneNumber}
              onChange={handlePhoneNumber}
              type="text"
              placeholder="Search With Mobile No"
            />
          ) : null}
        </div>
        {!isProfile ? (
          <div className="adminRechargesHeaderStatus">
            {statusOptions.map((status) => (
              <label key={`recharge-status-option-${status.value}`}>
                <input
                  onChange={(e) => setStatusValue(e.target.value)}
                  value={status.value}
                  name="status"
                  type="radio"
                  checked={statusValue === status.value}
                />
                {status.name}
              </label>
            ))}
          </div>
        ) : null}
      </div>

      <div className={`adminRechargesTable ${isProfile ? "isProfile" : ""}`}>
        <TableCommonToShowStatusRelated
          columns={normalRechargeTableDetails.columns}
          data={rechargeList.data}
          fetchList={fetchRechargeList}
          isStatusEdit={isStatusEdit}
          keys={normalRechargeTableDetails.keys}
          setIsStatusEdit={setIsStatusEdit}
          tableName={normalRechargeTableDetails.tableName}
          urlForUpdateStatus={URL.GET_ADMIN_RECHARGE}
          paginationName={paginationName}
          total_count={rechargeList.total_count}
        />
      </div>
    </div>
  );
};

export default AdminRecharges;

const normalRechargeTableDetails = {
  columns: [
    "Mobile No",
    "Plan",
    // "Receiver Name",
    // "Wallet Amount",
    // "Paid Amount",
    // "Transaction ID",
    // "Transaction SS",
    "Status",
  ],
  keys: [
    "mobile_no",
    "plan_level",
    // "amount_receiver_name",
    // "wallet_amount",
    // "balance_amount",
    // "upi_transaction_id",
    // "transaction_ss",
    "status",
  ],
  tableName: "Recharges",
};
